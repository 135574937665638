import React, {useState} from 'react'
import styled from 'styled-components'
import {Publishable} from 'Event/Dashboard/editor/views/Published'
import {FontStyle} from 'lib/ui/typography/FontStyleInput'
import {HasRules} from 'Event/attendee-rules'
import {HasSchedule} from 'lib/ui/layout/Scheduled'
import {HashMap, Ordered} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {useAttendeeVariables} from 'Event'
import {useTownhallTemplate} from 'Event/template/Townhall'
import StyledText from 'lib/ui/typography/StyledText'
import SectionHeading from 'Event/template/Townhall/Dashboard/Main/SectionHeading'
import {AgendaSectionConfig} from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaSectionConfig'
import Configurable from 'organization/Event/Configurable'
import AgendaListContent from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaList'
import SectionDescription from 'Event/template/Townhall/Dashboard/Main/SectionDescription'
import {BaseSectionProps} from 'Event/template/Townhall/Dashboard/Main/section'
import {SectionDraggable} from 'Event/template/Townhall/Dashboard/Main'
import SectionContainer from 'Event/template/Townhall/Dashboard/Main/SectionContainer'
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd'

export const AGENDA_SECTION = 'agenda'

export type AgendaSectionProps = BaseSectionProps &
  HasRules &
  HasSchedule & {
    name: typeof AGENDA_SECTION
    footer?: string
    footerFontStyles?: FontStyle[]
    items: HashMap<Agenda>
    separator: {
      color: string
    }
  }

export const createAgendaSection = (): AgendaSectionProps => ({
  name: AGENDA_SECTION,
  title: 'Agenda',
  description: '',
  footer: 'Agenda Time is in YOUR time zone, not ours',
  items: {},
  footerFontStyles: [],
  descriptionFontStyles: [],
  isEnabled: true,
  background: '#FFFFFF',
  color: null,
  font: null,
  separator: {
    color: '#000000',
  },
})

export type Agenda = Publishable &
  Ordered & {
    startDate: string
    endDate: string | null
    text: string
    description: string | null
    link: string | null
    speakers: string[]
  }

export default function AgendaSection(
  props: AgendaSectionProps & {
    sectionId: string
    index: number
  },
) {
  const {
    title,
    items,
    descriptionFontStyles,
    footer,
    description,
    footerFontStyles,
    isEnabled,
    sectionId,
    color,
  } = props
  const template = useTownhallTemplate()
  const isEdit = useEditMode()
  const v = useAttendeeVariables()
  const hasAgenda = Object.keys(items).length > 0

  const showing = hasAgenda && isEnabled

  const [dragHandleProps, setDragHandleProps] = useState<
    DraggableProvidedDragHandleProps | undefined
  >(undefined)

  if (!showing && !isEdit) {
    return null
  }

  return (
    <Configurable
      aria-label="agenda section config"
      editableProps={{handleProps: dragHandleProps}}
    >
      <AgendaSectionConfig agendaSection={props} sectionId={sectionId} />
      <SectionDraggable
        id={sectionId}
        index={props.index}
        setDragHandleProps={setDragHandleProps}
      >
        <SectionContainer {...props} id={sectionId}>
          <SectionHeading color={color} aria-label="agendas title">
            {title}
          </SectionHeading>
          <SectionDescription
            color={color}
            aria-label="agendas description"
            description={description}
            fontStyles={descriptionFontStyles}
          />
          <AgendaListContent {...props} />
          <StyledText
            Component={FooterText}
            fontStyles={footerFontStyles}
            aria-label="agendas footer description"
            color={template.textColor}
          >
            {v(footer || '')}
          </StyledText>
        </SectionContainer>
      </SectionDraggable>
    </Configurable>
  )
}

const FooterText = styled.p<{
  color: string
}>`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 0.16px;
  margin-top: ${(props) => props.theme.spacing[12]};
  margin-bottom: ${(props) => props.theme.spacing[6]};
`
