import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled, {keyframes} from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MuiTextField from '@material-ui/core/TextField'
import Button from 'lib/ui/Button'
import Dialog from 'lib/ui/Dialog'
import NameCompletionDialog from 'organization/Obie/Blocks/ProcessForm/NameCompletionDialog'
import TypingEffect from 'organization/Obie/Blocks/ProcessForm/TypeEffect'
import {categoryRoute} from 'organization/Obie/ObieRoutes'
import {
  AWAITING_MESSAGES,
  Completion,
  splitText,
  useObieService,
} from 'organization/Obie/ObieServiceProvider'
import {useOrganization} from 'organization/OrganizationProvider'

export default function Complete(props: {
  category_id: string
  dependencies?: string
}) {
  const {category_id, dependencies} = props
  const {routes} = useOrganization()
  const history = useHistory()
  const {
    awaitingCompletion,
    completion,
    findBlock,
    regenerateCompletion,
    setAwaitingCompletion,
    setCompletion,
  } = useObieService()

  const [allTyped, setAllTyped] = useState(false)
  const [nameDialogOpen, setNameDialogOpen] = useState<boolean>(false)
  const [localCompletion, setLocalCompletion] = useState<Completion | null>(
    null,
  )
  const [completionText, setCompletionText] = useState<string>('')
  const [
    regenerateInstructionsOpen,
    setRegenerateInstructionsOpen,
  ] = useState<boolean>(false)

  const awaitingMessageRef = useRef<string>('')

  // Need a cleanup of the completion in state when we're leaving this component.
  // There are some logic/condition/scenario hurdles to overcome where the
  // completion gets re-rendered when editing answers. This is the only way to
  // cleanup state with the multiple exit methods from the completion.
  useEffect(() => {
    return () => {
      setCompletion(undefined)
    }
  }, [setCompletion])

  // We need some local details when we actually have a completion from the
  // ObieServiceProvider
  useEffect(() => {
    if (!completion) {
      return
    }

    const splitTextParts = splitText(completion.completion)

    setLocalCompletion(completion)
    setCompletionText(splitTextParts[1])
  }, [completion, setLocalCompletion, setCompletionText])

  const toggleNameCompletionDialog = () =>
    setNameDialogOpen((current) => !current)

  const viewCompletions = () => {
    const cRoute = categoryRoute(routes, category_id)
    history.push(cRoute.root)
  }

  const preRegenerate = () => setRegenerateInstructionsOpen(true)
  const closePreGenerate = () => setRegenerateInstructionsOpen(false)

  const regenerate = useCallback(
    (extraInstructions: string) => {
      if (!localCompletion) {
        return
      }

      setRegenerateInstructionsOpen(false)
      setLocalCompletion(null)
      setAwaitingCompletion(true)
      awaitingMessageRef.current = ''

      regenerateCompletion(
        localCompletion.id,
        dependencies,
        extraInstructions,
      ).then((response) => {
        const splitTextParts = splitText(response.completion)

        setAwaitingCompletion(false)
        setLocalCompletion(response)
        setCompletionText(splitTextParts[1])
        setAllTyped(false)
      })
    },
    [
      localCompletion,
      dependencies,
      regenerateCompletion,
      setAwaitingCompletion,
    ],
  )

  if (awaitingCompletion) {
    if (!awaitingMessageRef.current) {
      awaitingMessageRef.current =
        AWAITING_MESSAGES[Math.floor(Math.random() * AWAITING_MESSAGES.length)]
    }

    return (
      <Container>
        <Content>
          <StyledTypingEffect text={awaitingMessageRef.current} />
          <Blinky />
        </Content>
      </Container>
    )
  }

  // If there isn't a completion available and we're not waiting for one, we
  // don't want to render anything...
  if (!localCompletion) {
    return null
  }

  const block = findBlock(localCompletion.block_id)

  return (
    <Container>
      <RegenerateInstructions
        open={regenerateInstructionsOpen}
        onClose={closePreGenerate}
        regenerate={regenerate}
      />

      <Content>
        <NameCompletionDialog
          completion={localCompletion}
          completionText={completionText}
          onClose={toggleNameCompletionDialog}
          viewCompletions={viewCompletions}
          open={nameDialogOpen}
        />
        <TypingEffect
          text={localCompletion.completion}
          onFinish={() => setAllTyped(true)}
          updateCompletion={setCompletionText}
        />
        <ButtonBox>
          <SaveCompletionButton
            type="button"
            variant="contained"
            color="primary"
            showing={allTyped}
            onClick={toggleNameCompletionDialog}
          >
            Save {block?.block || 'Block'}
          </SaveCompletionButton>
          <RegenerateCompletionButton
            type="button"
            variant="contained"
            color="primary"
            showing={allTyped}
            onClick={preRegenerate}
          >
            Regenerate
          </RegenerateCompletionButton>
        </ButtonBox>
      </Content>
    </Container>
  )
}

const RegenerateInstructions = (props: {
  open: boolean
  onClose: () => void
  regenerate: (regenerateInstructions: string) => void
}) => {
  const {onClose, open, regenerate} = props
  const [regenerateInstructions, setRegenerateInstructions] = useState<string>(
    '',
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setRegenerateInstructions(event.target.value)

  const handleClick = () => regenerate(regenerateInstructions)

  return (
    <Dialog expandable={false} open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        Are there any specific instructions you'd like me to know before I try
        again?
      </DialogTitle>
      <DialogContent>
        <MuiTextField
          value={regenerateInstructions}
          fullWidth
          multiline
          onChange={handleChange}
          rows={12}
          variant="filled"
        />

        <RegenerateButtonBox>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Regenerate
          </Button>
        </RegenerateButtonBox>
      </DialogContent>
    </Dialog>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Content = styled.div`
  position: relative;
  display: block;
  padding-left: ${(props) => props.theme.spacing[30]};
  padding-right: ${(props) => props.theme.spacing[30]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-left: ${(props) => props.theme.spacing[6]};
    padding-right: ${(props) => props.theme.spacing[6]};
  }
`

const ButtonBox = styled.div`
  display: flex;
  margin: ${(props) => props.theme.spacing[8]} 0;
  padding-bottom: ${(props) => props.theme.spacing[8]};
  justify-content: center;
`
const RegenerateButtonBox = styled.div`
  display: flex;
  margin: ${(props) => props.theme.spacing[8]} 0;
  justify-content: center;

  & > button:first-child {
    margin-right: ${(props) => props.theme.spacing[8]};
  }
`

const SaveCompletionButton = styled(Button)<{showing?: boolean}>`
  display: ${(props) => (props.showing ? 'block' : 'none')};
  margin-right: ${(props) => props.theme.spacing[4]};
`

const RegenerateCompletionButton = styled(Button)<{showing?: boolean}>`
  display: ${(props) => (props.showing ? 'block' : 'none')};
  margin-left: ${(props) => props.theme.spacing[4]};
`

const StyledTypingEffect = styled(TypingEffect)`
  display: inline-block;
  padding-right: 2px;
`
const blinker = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const Blinky = styled.div`
  display: inline;
  width: 1px;
  height: 20px;
  border-left: solid 2px #fff;
  animation: ${blinker} 0.8s infinite;
`
