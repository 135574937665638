import React from 'react'
import {usePurchaseForm} from './PurchaseFormProvider'
import {BlockComponentProps} from '../index'
import CountrySelect from '../../lib/ui/CountrySelect'
import StateSelect from '../../lib/ui/StateSelect'
import SectionLabel from './SectionLabel'

export interface ShippingAddressStepProps {
  sectionId?: string
  TextFieldComponent: React.FC<any>
  ShippingFieldsLabelComponent?: React.FC<any>
  shippingSectionLabel?: string
  input: {
    textColor: string
    fontSize: number
    backgroundColor: string
    spacing: number
    border: {
      width: number
      radius: number
      color: string
    }
    padding: {
      left: number
      right: number
      top: number
      bottom: number
    }
    placeholder: {
      firstName: string
      lastName: string
      email: string
      phone: string
      billingAddress1: string
      billingAddress2: string
      city: string
      state: string
      zipcode: string
      country: string
      shippingAddress1: string
      shippingAddress2: string
      shippingcity: string
      shippingstate: string
      shippingzipcode: string
      shippingcountry: string
    }
  }
}

export default function ShippingAddressFields(
  props: ShippingAddressStepProps & BlockComponentProps,
) {
  const {
    TextFieldComponent,
    ShippingFieldsLabelComponent = (props: {label?: string}) => (
      <SectionLabel {...props}>{props.label ?? ''}</SectionLabel>
    ),
    ...config
  } = props

  const {
    useSameAddressForShipping,
    shippingCountryId: countryId,
    shippingAddress1,
    setShippingAddress1,
    shippingAddress1Error,
    shippingAddress2,
    setShippingAddress2,
    setShippingCountryId,
    shippingCountryIdError,
    shippingStateId,
    setShippingStateId,
    shippingStateIdError,
    shippingCity,
    setShippingCity,
    shippingCityError,
    shippingZipPostal,
    setShippingZipPostal,
    shippingZipPostalError,
    billingAddress1,
    billingAddress2,
    billingCountryId,
    billingStateId,
    billingCity,
    billingZipPostal,
  } = usePurchaseForm()

  const selectorsClass = `country-state-selector`

  return (
    <>
      <style>
        {`
        .${selectorsClass} {
          margin-bottom: 0px;
        }

        .${selectorsClass} > div > div {
          background-color: ${config.input.backgroundColor};
          border: ${config.input.border.width}px solid;
          border-color: ${config.input.border.color};
          border-radius: ${config.input.border.radius}px !important;
          padding-left: ${config.input.padding.left}px;
          padding-right: ${config.input.padding.right}px !important;
          padding-top: ${config.input.padding.top}px;
          padding-bottom: ${config.input.padding.bottom}px;
          font-size: ${config.input.fontSize}px;
          line-height: normal;
        }

        .${selectorsClass} > div > div:focus {
          background-color: ${config.input.backgroundColor};
        }
     
        `}
      </style>
      <div>
        <ShippingFieldsLabelComponent
          {...props}
          label={props.shippingSectionLabel}
        />
        <TextFieldComponent
          aria-label={`shipping_address address 1`}
          config={config}
          placeholder={'shippingAddress1'}
          value={useSameAddressForShipping ? billingAddress1 : shippingAddress1}
          onChange={(e: any) => setShippingAddress1(e)}
          error={!!shippingAddress1Error}
          disabled={useSameAddressForShipping}
        />
        <TextFieldComponent
          aria-label={`shipping_address address 2`}
          config={config}
          placeholder={'shippingAddress2'}
          value={useSameAddressForShipping ? billingAddress2 : shippingAddress2}
          onChange={(e: any) => setShippingAddress2(e)}
          disabled={useSameAddressForShipping}
        />
        <CountrySelect
          config={config}
          className={selectorsClass}
          value={useSameAddressForShipping ? billingCountryId : countryId}
          onChange={(value) => setShippingCountryId(value)}
          defaultLabel={config.input.placeholder.shippingcountry}
          error={!!shippingCountryIdError}
          disabled={useSameAddressForShipping}
        />
        <StateSelect
          config={config}
          className={selectorsClass}
          value={useSameAddressForShipping ? billingStateId : shippingStateId}
          onChange={(value) => setShippingStateId(value)}
          countryId={useSameAddressForShipping ? billingCountryId : countryId}
          defaultLabel={config.input.placeholder.shippingstate}
          error={!!shippingStateIdError}
          disabled={useSameAddressForShipping}
        />
        <TextFieldComponent
          aria-label={`shipping_address city`}
          config={config}
          placeholder={'shippingcity'}
          name={`shipping_address.city`}
          value={useSameAddressForShipping ? billingCity : shippingCity}
          onChange={(e: any) => setShippingCity(e)}
          error={!!shippingCityError}
          disabled={useSameAddressForShipping}
        />
        <TextFieldComponent
          aria-label={`shipping_address postal`}
          config={config}
          placeholder={'shippingzipcode'}
          name={`shipping_address.zip_postal`}
          value={
            useSameAddressForShipping ? billingZipPostal : shippingZipPostal
          }
          onChange={(e: any) => setShippingZipPostal(e)}
          error={!!shippingZipPostalError}
          disabled={useSameAddressForShipping}
        />
      </div>
    </>
  )
}
